import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";

const TextParallelogramContainer = ({ children, ...props }) => {
  return (
    <>
      <div className="text-parallelogram-container" {...props}>
        {children}
      </div>
    </>
  );
};

const TextParallelogram = ({ nor, children, ...props }) => {
  return (
    <>
      <div className="text-parallelogram-wrapper">
        <img
          className="text-star"
          src={process.env.PUBLIC_URL + "/img/bg-text-light2.png"}
          alt="star"
        />
        <div className={classnames("text-parallelogram", { nor })} {...props}>
          {children}
        </div>
      </div>
    </>
  );
};

const TextLightPoint = ({ children, ...props }) => {
  return (
    <>
      <div className="text-light-point" {...props}>
        {children}
      </div>
    </>
  );
};

const TextWithStar = ({ className = "", bg = true, children, ...props }) => {
  return (
    <>
      <div className={`text-with-star ${className}`}>
        <div className="text-with-star-content" {...props}>
          {/* {bg ? (
            <img
              className="text-star"
              src={process.env.PUBLIC_URL + "/img/bg-text-light.png"}
              alt="star"
            />
          ) : undefined} */}
          {children}
        </div>
      </div>
    </>
  );
};

const TextLineMiddle = ({ children, ...props }) => {
  return (
    <>
      <div className="text-line-middle" {...props}>
        {children}
      </div>
    </>
  );
};

export {
  TextLightPoint,
  TextWithStar,
  TextParallelogramContainer,
  TextParallelogram,
  TextLineMiddle,
};
