import React from "react";
import { Route, Redirect } from "react-router-dom";

function GuardRoute({ user, component: Component, render, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          render ? (
            render(props)
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/init",
              state: { from: props.location },
              search: props.location.search,
            }}
          />
        )
      }
    />
  );
}

export default GuardRoute;
