import React, { Suspense } from "react";
import styled from "styled-components";
import Accordion from "./accordion";

const LAYOUT = {
  heightFooter: "187px",
  heightHeader: "167px",
  paddingHead: "240px",
};

const StyledContainer = styled.div`
  background-image: linear-gradient(to top, #181529, #262452, #2b2955, #404481);
  min-height: 100%;
  padding-top: ${(props) => props.header && LAYOUT.paddingHead};
  padding-bottom: ${(props) => props.footer && LAYOUT.heightFooter};
  /*  */
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`;
const StyledBackground = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${(props) => props.bg && "url(/img/bg-lck-logo.png)"};
  background-repeat: no-repeat;
  /*  */
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  overflow: hidden;
`;

const Container = ({
  bg = true,
  header = true,
  footer = true,
  beforeEl,
  afterEl,
  children,
  ...props
}) => {
  return (
    <StyledContainer {...props} footer={footer} header={header}>
      {beforeEl}
      <StyledBackground bg={bg}>{children}</StyledBackground>
      {afterEl}
    </StyledContainer>
  );
};
export default Container;
