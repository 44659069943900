"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true,
});
exports["default"] = void 0;
// Korean
var strings = {
  prefixAgo: null,
  prefixFromNow: null,
  suffixAgo: "전",
  suffixFromNow: "후",
  seconds: "방금",
  minute: "1분",
  minutes: "%d분",
  hour: "1시간",
  hours: "%d시간",
  day: "하루",
  days: "%d일",
  week: "1주",
  weeks: "%d주",
  month: "1개월",
  months: "%d개월",
  year: "1년",
  years: "%d년",
  wordSeparator: " ",
};
var _default = strings;
exports["default"] = _default;
