import { useLocation } from "react-router-dom";
import { key, iv } from "../config/crypto.json";

const CryptoJS = window.CryptoJS;

export function useSearchUser() {
  const location = useLocation();
  const { search } = location;
  const decoded = aes256Decode(search.substr(1));

  /**@search */
  const searchParams = new URLSearchParams(decoded);
  //required
  const sp = searchParams.get("sp"); // 간편회원여부 Y || N (포멧)
  const id = searchParams.get("id"); // 통합고객번호  11자리 번호
  const name = searchParams.get("name"); // 김형준 (포멧)
  const regDate = searchParams.get("regDate"); // 20211004 (포멧)
  const ci = searchParams.get("ci"); // + 문자열 길이 : 88 ex)712fd8570efccd8d20911b92b852f4
  const tel = searchParams.get("tel"); // + 010-2806-1157 (포멧)
  const gender = searchParams.get("gender"); // + 01 || 02 (포멧)
  //optional
  const isPush = searchParams.get("isPush"); // 푸시여부 Y || N (포멧)
  const isMarketing = searchParams.get("isMarketing"); // 마케팅여부 Y || N (포멧)
  const isInformation = searchParams.get("isInformation"); // 상품여부 Y || N  (포멧)
  const wid = searchParams.get("wid"); // GUDWNS86 : 간편회원의 경우 문자열 길이 : 12
  const friend = searchParams.get("friend"); // 친구추천코드이벤트로가입시에만전달
  const op = searchParams.get("op"); // option

  function aes256Decode(data) {
    const cipher = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(key), {
      iv: CryptoJS.enc.Utf8.parse(iv),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });
    return cipher.toString(CryptoJS.enc.Utf8);
  }

  function YYYYMMDDtoISO(YYYYMMDD = null) {
    if (!YYYYMMDD) return null;
    if (YYYYMMDD?.length == 8)
      YYYYMMDD = `${YYYYMMDD.substring(0, 4)}-${YYYYMMDD.substring(4, 6)}-${YYYYMMDD.substring(6, 8)}`;
    const d = new Date(YYYYMMDD);
    if (isNaN(d.getDate())) return null;
    return YYYYMMDD;
  }

  return {
    ci,
    sp,
    id,
    name,
    tel,
    gender,
    regDate: YYYYMMDDtoISO(regDate),
    wid,
    isPush,
    isMarketing,
    isInformation,
    friend,
    op,
  };
}
