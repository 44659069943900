import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

/*
 *https://developers.google.com/youtube/player_parameters#Overview
 */

const StyledYoutube = styled.div`
  overflow: hidden;
  position: relative;
  margin-top: 62px;
  padding-bottom: 272px;
  width: 100%;
`;

const StyledIframe = styled.iframe`
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  height: 272px;
  position: absolute;
`;

const YoutubeEmbed = ({ embedId }) => (
  <StyledYoutube className={"media-item"}>
    <StyledIframe
      className={"media-frame"}
      src={`https://www.youtube.com/embed/${embedId}?playsinline=0&modestbranding=1`}
      frameBorder="0"
      showinfo="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </StyledYoutube>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
