import Loading from "./loading";
import Input from "./input";
import Menu from "./menu";
import Navigator from "./navigator";
import Pagination from "./pagination";
import Search from "./search";
import ScrolledSection from "./scrolled-section";
import YoutubeEmbed from "./youtubeEmbed";
import NoData from "./noData";
import Header from "./header";
import Body from "./body";
import Footer from "./footer";
import Sidebar from "./sidebar";
import Container from "./container";
import Accordion from "./accordion";
import Modal from "./modal";
import {
  Spacer,
  Comment,
  Section,
  TeamLogo,
  PlayerImg,
  Html,
  ThemeDarkContainer,
  Loader,
  CenterAbolute,
} from "./el";
import {
  TextLightPoint,
  TextWithStar,
  TextParallelogramContainer,
  TextParallelogram,
  TextLineMiddle,
} from "./textStyle";
/**@components_index */

export {
  Loading,
  Menu,
  Input,
  Navigator,
  Pagination,
  Search,
  ScrolledSection,
  YoutubeEmbed,
  NoData,
  Footer,
  Header,
  Body,
  Sidebar,
  Container,
  Accordion,
  Modal,
  Spacer,
  Comment,
  Section,
  TeamLogo,
  PlayerImg,
  Html,
  ThemeDarkContainer,
  Loader,
  CenterAbolute,
  TextLightPoint,
  TextWithStar,
  TextParallelogramContainer,
  TextParallelogram,
  TextLineMiddle,
};
