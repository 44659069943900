import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import classnames from "classnames";

const ScrolledSection = React.memo(({ className = "", children, offeset = 0, ...props }) => {
  const divEl = useRef(null);
  const [on, setON] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      handleScroll();
    }, 200);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [children]);

  const handleScroll = useCallback(
    (e) => {
    const offsetTop = divEl?.current?.offsetTop + offeset + divEl?.current?.offsetHeight;
    const scollPosition = window.innerHeight + window.scrollY;
    if (offsetTop <= scollPosition && !on) {
      setON(true);
      return window.removeEventListener("scroll", handleScroll);
    }
  },
  [children],
);

  return (
    <div ref={divEl} className={classnames("section " + className, { active: on })} {...props}>
      {children}
    </div>
  );
});
export default ScrolledSection;
