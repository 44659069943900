import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TimeAgo from "react-timeago";
import classnames from "classnames";
import styled, { css } from "styled-components";
/**@lib */ // import krStrings from "react-timeago/lib/language-strings/ko";
import krStrings from "../config/koString";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { ScrolledSection, NoData } from "./index";
import { badwords } from "../config/badwords.json";
import { addComma, filterBadWord } from "../lib/util";
import { useAxios, Util } from "../lib";
/**@el */

const Spacer = ({ px }) => <div style={{ paddingTop: `${px}px` }} />;

const IConLike = ({ acitve, onClickLike = null }) => <div onClick={onClickLike} className={`ic-like ${acitve ? "active" : ""}`} />;

const InputComment = ({ onAddComment = null, special = false }) => {
  const [comment, setCommnet] = useState("");

  useEffect(() => () => {
    document.querySelector("html").removeAttribute("style");
    document.querySelector("body").removeAttribute("style");
  }, []);

  const handleFocus = () => {
    document.querySelector("html").setAttribute("style", "height:auto;overflow:auto;");
    document.querySelector("body").setAttribute("style", "height:auto;overflow:auto;");
  };

  const handleBlur = () => {
    document.querySelector("html").removeAttribute("style");
    document.querySelector("body").removeAttribute("style");
  };

  return (
    <div className={"comment-item-wrapper"}>
      <input
        className={"input-comment"}
        type={"text"}
        placeholder={special ? "댓글 추가" : "LCK 선수들에게 댓글을 남겨주세요"}
        maxLength={128}
        value={comment}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={e => {
          setCommnet(e.target.value);
        }}
      />
      <button
        className={"btn-comment"}
        disabled={!comment}
        onClick={
          onAddComment
            ? e => {
              onAddComment(comment);
              setCommnet("");
            }
            : null
        }
      >
        등록
      </button>
    </div>
  );
};

const CommentContainer = styled.div`
  &.light {
    color: ${({ theme }) => theme.black};
    background-color: ${({ theme }) => theme.bg};
    font-weight: bold;
    .comment-header {
      border-color: ${({ theme }) => theme.black};
      .comment-header-title {
        color: inherit;
        background-image: url(/icon/interaction/ic-message-light.png);
      }
      .comment-header-desc {
        color: rgba(25, 23, 41, 0.5);
      }
    }
    .comment-item-wrapper {
      border-color: ${({ theme }) => theme.black};
      .input-comment {
        height: 137px;
        color: inherit;
      }
      .btn-comment {
        color: #463cc4;
        width: 133px;
        height: 76px;
        border-radius: 37.5px;
        border: solid 3px #463cc4;
        background-color: transparent;
      }
    }
    .comment-content {
      color: inherit;
      border-color: ${({ theme }) => theme.black};
      .comment-content-title,
      .comment-content-desc,
      .comment-content-info {
        color: inherit;
      }
      time {
        font-weight: 300;
      }
    }
    .count {
      font-weight: bold;
    }
    .btn-inverse {
      /* background-color: transparent;
      border: solid 2px #463cc4;
      color: #463cc4;
      -webkit-text-fill-color: #463cc4; */
    }
  }
`;

const IconBestStyle = styled.div`
  width: 127px;
  height: 52px;
  border-radius: 35px;
  background-color: #5fa6f9;
  display: inline;
  color: #fff;
  font-size: 32px;
  padding: 8px 27px;
  margin-right: 20px;
  &:after {
    content: "BEST";
  }
`;

const Comment = ({ eventId, disabled = false, callback = null, theme = "", best = true }) => {
  const throtle = 3;
  const likeCondition = 5;

  const { user } = useSelector(state => state.userState);
  const [slicing, setSlicing] = useState(throtle);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const formatter = buildFormatter(krStrings);
  const httpClient = useAxios();

  const themeLight = {
    bg: "#f1f1f1",
    black: " #191729",
  };

  useEffect(() => {
    getComment(eventId);
    setSlicing(throtle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const loadmore = () => {
    setLoading(true);
    setTimeout(() => {
      const update = comments.length < slicing ? slicing : slicing + throtle;
      setSlicing(update);
      setLoading(false);
    }, 100);
  };

  const getComment = (async (id, isAdd) => {
    if (!id) return;
    let url = "/comment/" + id;
    const res = await httpClient.get(url);
    let comments = res?.data;
    /**@BEST_MODE */
    if (best) {
      const mostLikes = comments
        .filter(r => r?.cnt >= likeCondition)
        .sort((a, b) => b?.cnt - a?.cnt)
        .splice(0, 3);
      mostLikes.forEach(r => {
        comments.splice(
          comments.findIndex(j => j?.id == r?.id),
          1,
        );
      });
      comments = [...mostLikes?.map(r => ({ ...r, isBest: true })), ...comments];
    }
    if (isAdd) setSlicing(slicing + 1);
    setComments(comments || []);
  });

  const addComment = text => {
    let url = "/comment";
    const form = { eventId, text };
    httpClient.post(url, form).then(r => {
      if (callback) callback(form);
      setTimeout(() => {
        getComment(eventId, true);
      }, 100);
    });
  };

  const onClickLike = comment => {
    const { id } = comment;
    const form = { commentId: id };
    let url = "/comment/like";
    httpClient.put(url, form).then(r => {
      setTimeout(() => {
        getComment(eventId);
      }, 100);
    });
  };

  const removeComment = ({ id }) => {
    if (window.confirm("삭제하시겠습니까?")) {
      let url = "/comment";
      const config = {
        data: { id },
      };
      httpClient.delete(url, config).then(r => {
        setTimeout(() => {
          getComment(eventId);
        }, 100);
      });
    }
  };

  return (
    <CommentContainer
      theme={theme == "light" ? themeLight : undefined}
      className={classnames("comment-container", {
        light: theme == "light",
      })}
    >
      <div>
        <div className={"comment-header"}>
          <div className={"comment-header-title"}>댓글</div>
          <div className={"comment-header-desc"}>{addComma(comments?.length)}개</div>
        </div>
        {disabled ? undefined : <InputComment onAddComment={addComment} special={callback ? true : false} />}
        {comments.slice(0, slicing).map((r, i) => {
          const { wid, tier } = r || {};
          return (
            <div key={i}>
              <div className={"comment-content"}>
                <div className={"comment-content-title"}>
                  <div className="user-info">
                    <div className={"user-name"}>{Util.maskingName(wid)}</div>
                    <IConTier tier={tier} />
                  </div>
                  {r.userId === user.id && !disabled ? <ButtonClose onClick={() => removeComment(r)} /> : undefined}
                </div>
                <div className={"comment-content-desc"}>
                  {r?.isBest ? <IconBestStyle /> : undefined}
                  {filterBadWord(r?.text, badwords)}
                </div>
                <div className={"comment-content-info"}>
                  <TimeAgo date={r?.createdAt} formatter={formatter} />
                  <div className={"comment-content-info-like"}>
                    <IConLike acitve={r.like || false} onClickLike={onClickLike ? () => onClickLike(r) : null} />
                    <div className="count">{addComma(r?.cnt || 0)}</div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div style={{ padding: "80px 45px 150px" }}>
          <button
            className={classnames("btn-inverse block", {
              disabled: comments?.length <= slicing,
            })}
            disabled={comments?.length <= slicing}
            onClick={loadmore}
          >
            더보기
          </button>
        </div>
      </div>
    </CommentContainer>
  );
};

const Section = ({ type, title, data, offeset, label, callback = null, loading = false, ...props }) => {
  const backgroundImage = data?.img
    ? `url(${process.env.REACT_APP_API}/uploads/${type}/${data?.img}),linear-gradient(to top, #cecffb, #a194f7)`
    : "linear-gradient(to top, #cecffb, #a194f7)";
  return (
    <>
      <div>
        <h1 className={"section-head"}>{title || data?.title || ""}</h1>
        {loading ? (
          <div className="section-square">
            <CenterAbolute>
              <Loader />
            </CenterAbolute>
          </div>
        ) : data ? (
          <ScrolledSection
            className={"section-square"}
            offeset={offeset}
            style={{
              backgroundImage,
            }}
          >
            <div className={"toRight-wrapper"}>
              <div className={"btn-toRight"} onClick={callback}>
                <h3>{label ? label : "참여하기"}</h3>
                <span className={"icon-arrow"} />
              </div>
            </div>
          </ScrolledSection>
        ) : (
          <div className="section-square">
            <NoData />
          </div>
        )}
      </div>
    </>
  );
};

const TeamLogo = ({ code, fallback, ...props }) => {
  const { teams } = useSelector(state => state.appState);
  let image = "";
  if (code && teams && teams.find(r => r.code === code)) {
    image =
      process.env.REACT_APP_LCK_API_STATIC +
      "/teams/" +
      teams
        .find(r => r.code === code)
        ?.image?.split("/")
        .pop();
  }
  const src = image || fallback;
  return (
    <img
      {...props}
      alt={"team-logo"}
      className={"team-logo"}
      src={src}
      onError={e => (e.target.src = process.env.PUBLIC_URL + "/icon/no-team.png")}
    />
  );
};

const PlayerImg = ({ img = "", fallback }) => (
  <img
    alt={"profile"}
    className={"player-img"}
    src={img?.replace(/^http:\/\//i, "https://") || fallback}
    onError={e => (e.target.src = process.env.PUBLIC_URL + "/icon/no-player.png")}
  />
);

// eslint-disable-next-line no-unused-vars
const ButtonMore = ({ ...props }) => (
  <div className={"btn-more"} {...props}>
    {Array(3)
      .fill()
      .map((r, i) => (
        <div key={i} />
      ))}
  </div>
);

const ButtonCloseStyle = styled.div`
  position: absolute;
  width: inherit;
  height: inherit;
  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: #191729;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;
const ButtonCloseContainer = styled.div`
  cursor: pointer;
  position: relative;
  width: 32px;
  height: 32px;
`;

const ButtonClose = ({ ...props }) => (
  <ButtonCloseContainer {...props}>
    <ButtonCloseStyle></ButtonCloseStyle>
  </ButtonCloseContainer>
);

const IConTierContainer = styled.div`
  width: 41px;
  height: 41px;
  background-size: 100%;
`;

const IConTier = ({ tier = "bsg" }) => (
  <IConTierContainer className={"icon-tier"} style={{ backgroundImage: ` url(/icon/tier/ic-tier-${tier}.png)` }} />
);

const paddingSideMyContent = 45;
const StyledHtml = styled.div`
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  font-family: NotoSansKR;
  word-break: break-all;
  padding: 0 ${paddingSideMyContent}px;
  b,
  strong {
    font-weight: bolder;
  }
  img {
    ${props =>
    props.fullImg
      ? css`
            margin-left: -${paddingSideMyContent}px;
            width: calc(100% + ${paddingSideMyContent * 2}px);
            max-width: calc(100% + ${paddingSideMyContent * 2}px);
          `
      : css`
            width: 100%;
          `}
  }
  &.fullImg {
    img {
      margin-left: -${paddingSideMyContent}px;
      width: calc(100% + ${paddingSideMyContent * 2}px);
      max-width: calc(100% + ${paddingSideMyContent * 2}px);
    }
  }
`;

const Html = ({ html = "", fullImg = false, ...props }) => (
  <StyledHtml
    /**@skeleton  className={is && "yes"} */
    {...props}
    dangerouslySetInnerHTML={{ __html: html }}
    fullImg={fullImg}
  />
);

const ThemeDark = styled.div`
  background-color: #131318;
`;

const ThemeDarkContainer = ({ ...props }) => <ThemeDark {...props} />;

const StyledLoader = styled.div`
  overflow: hidden;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  & div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  & div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
`;

const Loader = ({ ...props }) => (
  <StyledLoader>
    <div />
    <div />
  </StyledLoader>
);

const StyledCenterAbolute = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenterAbolute = ({ children }) => <StyledCenterAbolute>{children}</StyledCenterAbolute>;

export { Spacer, Comment, Section, TeamLogo, PlayerImg, Html, ThemeDarkContainer, Loader, CenterAbolute };
