import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
// import "core-js/stable";
// import "./polyfill";
// import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
// import "./normalize.css";
import "./reset.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "./config/store";
import { BrowserRouter } from "react-router-dom";
const { store, persistor } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById("root"),
);
reportWebVitals();
serviceWorker.unregister();
