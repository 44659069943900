import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
/**@lib */

/**@httpClient */
export function useAxios() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { search, pathname } = location;

  const config = {
    baseURL: process.env.REACT_APP_API,
    withCredentials: true,
  };

  const reset = () => {
    dispatch({ type: "reset" });
    axios.get("/deauth", config);
    localStorage.clear();
  };

  const httpErr = (cause = 500) => {
    history.replace({
      pathname: "/err",
      search: `?returnUrl=${pathname}`,
      state: { cause },
    });
  };

  return useMemo(() => {
    const instance = axios.create(config);
    instance.interceptors.request.use(req => req);
    instance.interceptors.response.use(
      res => res,
      async err => {
        process?.env?.NODE_ENV != "production" && console.error(err);
        /**@auth */
        if (err?.response?.status == 500) httpErr(500);
        else if (err?.response?.status == 401 || err?.response?.status == 403) {
          reset();
          if (err?.config?.url == "/user/refresh" && err?.config?.data) {
            const json = JSON.parse(err.config.data) || ""; //replace
            if (json?.id && json?.ci) return history.replace({ pathname: "/init", search });
          }
        }
        throw err;
      },
    );
    return instance;
  }, []);
}

export function useAxiosEsport() {
  return useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_LCK_API,
      timeout: 30000, //err.code == "ECONNABORTED"
      headers: { "x-api-key": process.env.REACT_APP_LCK_KEY },
    });
    instance.interceptors.response.use(
      res => res,
      err => {
        throw err;
      },
    );
    return instance;
  }, []);
}
