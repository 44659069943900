import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const StyledMenu = styled.div`
  display: inline;
  cursor: pointer;
  position: relative;
  .submenu {
    display: none;
    position: absolute;
    right: 0;
    padding: 10px 0;
    .items {
      background: #fff;
      padding: 0 15px;
      border-radius: 2px;
      box-shadow: 0 4px 24px 0 rgba(26, 22, 67, 0.27);
    }
  }
  &.show {
    .submenu {
      display: block;
    }
  }
`;

const Menu = ({ children, anchorEl, hoverable = false }) => {
  let location = useLocation();
  const refEl = useRef(null);
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    window.addEventListener("click", closeMenu);
    return () => window.removeEventListener("click", closeMenu);
  }, [isShow]);

  useEffect(() => {
    setIsShow(false);
  }, [location]);

  const showMenu = (e) => {
    e.preventDefault();
    setIsShow(true);
  };

  const closeMenu = (e) => {
    if (!refEl.current || !refEl.current.contains) return;
    if (!refEl.current.contains(e.target)) {
      setIsShow(false);
    }
  };

  const onHover = (e) => {
    if (isShow || !hoverable) return;
    showMenu(e);
  };

  const onLeave = (e) => {
    if (!hoverable) return;
    setIsShow(false);
  };

  return (
    <StyledMenu
      className={`menu ${isShow ? "show" : ""}`}
      onClick={showMenu}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      ref={refEl}
    >
      {anchorEl}
      {isShow ? (
        <div className="submenu">
          <div
            className={"items"}
            onClick={(e) => {
              setIsShow(false);
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {children}
          </div>
        </div>
      ) : null}
    </StyledMenu>
  );
};
export default Menu;
