import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { RoutesMain } from "../config";

import { Loading } from "../components";

const Body = ({ children }) => {
  return <Suspense fallback={<Loading />}>{children}</Suspense>;
};
export default Body;
