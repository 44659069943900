export function isEmptyKey(param) {
  return Object.keys(param).length === 0;
}

export const isEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

export function isEquivalent(a, b) {
  let aProps = Object.getOwnPropertyNames(a);
  let bProps = Object.getOwnPropertyNames(b);
  if (aProps.length !== bProps.length) {
    return false;
  }
  for (let i = 0; i < aProps.length; i++) {
    let propName = aProps[i];
    if (a[propName] !== b[propName]) {
      return false;
    }
  }
  return true;
}

export const cookieSeperator = (cookie) => {
  return cookie.split(";").reduce((res, item) => {
    const data = item.trim().split("=");
    return { ...res, [data[0]]: data[1] };
  }, {});
};

export function addComma(num = 0) {
  const regexp = /\B(?=(\d{3})+(?!\d))/g;
  return num.toString().replace(regexp, ",");
}

export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function createThrottledFunction(fn, throttleMs) {
  let shouldThrottle = false;
  return (param) => {
    if (shouldThrottle) {
      return;
    }
    shouldThrottle = true;
    fn(param);
    setTimeout(() => {
      shouldThrottle = false;
    }, throttleMs);
  };
}

export function createDebouncedFunction(fn, debounceMs) {
  let timer = null;
  return (param) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn(param);
      timer = null;
    }, debounceMs);
  };
}

export const saveData = (function () {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  return function (data, fileName) {
    const json = JSON.stringify(data),
      blob = new Blob([json], { type: "octet/stream" }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();

export const recursiveMap = (childrenKey) => (transformer) => (arr) => {
  const inner = (arr = []) =>
    arr.map(({ [childrenKey]: children, ...rest }) => ({
      ...transformer(rest),
      ...(children && { nodes: inner(children) }), // ...(children && { [childrenKey]: inner(children) }),
    }));
  return inner(arr);
};

export function increaseHexbrightness(hex, percent) {
  hex = hex.replace(/^\s*#|\s*$/g, "");
  if (hex.length === 3) {
    hex = hex.replace(/(.)/g, "$1$1");
  }
  const r = parseInt(hex.substr(0, 2), 16),
    g = parseInt(hex.substr(2, 2), 16),
    b = parseInt(hex.substr(4, 2), 16);
  return (
    "#" +
    (0 | ((1 << 8) + r + ((256 - r) * percent) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + g + ((256 - g) * percent) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + b + ((256 - b) * percent) / 100)).toString(16).substr(1)
  );
}

export function getyyyyMMdd(time) {
  const x = time ? new Date(time) : new Date();
  const year = x.getFullYear().toString();
  let MM = (x.getMonth() + 1).toString();
  let dd = x.getDate().toString();
  if (dd.length === 1) {
    dd = "0" + dd;
  }
  if (MM.length === 1) {
    MM = "0" + MM;
  }
  const yyyyMMdd = `${year}-${MM}-${dd}`;
  return yyyyMMdd;
}

export function timestamp(time) {
  function pad(n) {
    return n < 10 ? "0" + n : n;
  }
  const d = time ? new Date(time) : new Date();
  return (
    d.getFullYear() +
    "-" +
    pad(d.getMonth() + 1) +
    "-" +
    pad(d.getDate()) +
    " " +
    pad(d.getHours()) +
    ":" +
    pad(d.getMinutes()) +
    ":" +
    pad(d.getSeconds())
  );
}

export function getDayLocale(time) {
  let day = time ? new Date(time) : new Date();
  /**@const ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];*/
  const WEEKDAY = ["일", "월", "화", "수", "목", "금", "토"];
  let week = WEEKDAY[day.getDay()];
  return week;
}

export function getDateTimeValue(time = null) {
  const getTimed = time ? new Date(time).getTime() : new Date().getTime();
  const tzoffset = new Date().getTimezoneOffset() * 60000;
  const localISOTime = new Date(getTimed - tzoffset).toISOString().substring(0, 16);
  return localISOTime;
}

export function searchParams() {
  let match,
    pl = /\+/g,
    search = /([^&=]+)=?([^&]*)/g,
    decode = function (s) {
      return decodeURIComponent(s.replace(pl, " "));
    },
    query = window.location.search.substring(1);

  let urlParams = {};
  while ((match = search.exec(query))) urlParams[decode(match[1])] = decode(match[2]);
  return urlParams;
}

export function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function arrayDiff(a, b) {
  const diffIndexes = [];
  return a.filter(function (i) {
    if (b.indexOf(i) < 0) {
      diffIndexes.push(a.indexOf(i));
      return true;
    } else {
      return false;
    }
  });
}

export function removeDuplicates(arr) {
  return Object.keys(
    arr.reduce((acc, val) => {
      acc[JSON.stringify(val)] = 1;
      return acc;
    }, {}),
  ).map((val, key, array) => JSON.parse(array[key]));
}

export function getUnique(arr, comp) {
  const unique = arr
    .map((e) => e[comp])
    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)
    // eliminate the dead keys & store unique objects
    .filter((e) => arr[e])
    .map((e) => arr[e]);
  return unique;
}

export function isNumberKey(event) {
  if (event.keyCode < 48 || event.keyCode > 57) {
    return false;
  }
  return true;
}

export function setCookie(cname, cvalue, exdays) {
  let d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function isMobile() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

export function detectIE() {
  let ua = window.navigator.userAgent;
  // IE 10 ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
  // IE 11 ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
  // Edge 12 (Spartan) ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
  // Edge 13 ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';
  let msie = ua.indexOf("MSIE ");
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
  }
  let trident = ua.indexOf("Trident/");
  if (trident > 0) {
    // IE 11 => return version number
    let rv = ua.indexOf("rv:");
    return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
  }
  let edge = ua.indexOf("Edge/");
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
  }
  // other browser
  return false;
}

export function sendFcmToken(url, serialized, token) {
  const xhr = new XMLHttpRequest();
  xhr.open("POST", url);
  xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
  xhr.setRequestHeader("Authorization", "Bearer " + token);
  xhr.send(serialized);
}

export function cloneObj(ojb) {
  return JSON.parse(JSON.stringify(ojb));
}

export const isExpired = (exp) => {
  const due = typeof exp === "number" ? exp : parseInt(exp);
  if (new Date(due * 1000).getTime() < new Date().getTime()) return true;
  return false;
};

export function withoutTime(time) {
  const date = time ? new Date(time) : new Date();
  date.setHours(0, 0, 0, 0);
  return date;
}

export const setTime = ({ time = null, year = 0, month = 0, date = 0, hour = 0, min = 0 }) => {
  var d = time ? new Date(time) : new Date();
  return new Date(
    d.getFullYear() + year,
    d.getMonth() + month,
    d.getDate() + date,
    d.getHours() + hour,
    d.getMinutes() + min,
  );
};

export function compareDate(startDate, endDate, timeStrict = true) {
  let today = new Date();
  if (!timeStrict) {
    startDate = withoutTime(startDate);
    endDate = withoutTime(endDate);
    today = withoutTime(today);
  }
  let progress = "inprogress";
  if (new Date(startDate) > today) {
    progress = "unstarted";
  } else if (new Date(endDate) < today) {
    progress = "completed";
  }
  return progress;
}

export const filterBadWord = (msg, badWords = []) => {
  const regExpSource = badWords.join("|");
  const regExpFilter = new RegExp(regExpSource, "ig");
  return msg.replace(regExpFilter, (msg) => {
    return msg.replace(/./g, "*");
  });
};

export function getYoutubeID(url) {
  var ID = "";
  url = url.replace(/(>|<)/gi, "").split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_\-]/i);
    ID = ID[0];
  } else {
    ID = url;
  }
  return ID;
}

export const maskingName = function (strName = "") {
  if (!strName) return "";
  if (strName.length > 2) {
    var originName = strName.split("");
    originName.forEach(function (name, i) {
      if (i === 0 || i === originName.length - 1) return;
      originName[i] = "*";
    });
    var joinName = originName.join();
    return joinName.replace(/,/g, "");
  } else {
    var pattern = /.$/;
    return strName.replace(pattern, "*");
  }
};

export const isDoneToday = (param) => {
  const { key = "today", ok = false } = param || {};
  const date = new Date().toLocaleDateString();
  const batch = localStorage.getItem(key);
  if (ok) {
    localStorage.setItem(key, date);
  }
  if (batch) {
    if (batch == date) {
      return true;
    }
  }
  return false;
};

export const getRecentClosestIdx = (timeList = [], timeStrict = false) => {
  const today = timeStrict ? withoutTime().getTime() : new Date().getTime();
  const idx = timeList?.reduce(
    (prev, curr) => (Math.abs(curr - today) < Math.abs(prev - today) ? curr : prev),
    0,
  );
  return timeList.findIndex((r) => r == idx);
};
