import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
/**@lib */
import { CATEGORY_TYPE } from "../config/const";
import defualtTeams from "./teams.json";
/**@store */

const initialAppState = {
  online: true,
  loading: false,
  teams: defualtTeams,
  noticeCnt: 0,
  noticeNew: false,
  orderCnt: 0,
  orderNew: false,
};

const appState = (state = initialAppState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const initialLoadState = {
  show: false,
  pageName: "",
  tabsLCK: [
    { name: "SCHEDULE", isActive: true },
    { name: "STANDING", isActive: false },
    { name: "ROSTER", isActive: false },
  ],
  tabsEvent: [
    { name: "주간 골드킹", isActive: false, key: CATEGORY_TYPE.goldking },
    { name: "승부예측", isActive: true, key: CATEGORY_TYPE.predict },
    { name: "특별 이벤트", isActive: false, key: CATEGORY_TYPE.special },
  ],
  tabsReward: [
    { name: "골드 현황", isActive: true },
    { name: "골드 교환소", isActive: false },
  ],
  tabsTier: [
    { name: "임무", isActive: true },
    { name: "혜택", isActive: false },
  ],
  terms: false,
  privacy: false,
  isDupClick: false,
};

const loadState = (state = initialLoadState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const initialUserState = {
  /**@Sign */
  authenticated: false,
  /**@User  */
  user: null,
  /**@Details  */
  hitRate: 0,
  lp: null,
  point: 0,
  notification: {
    pushMatch: 0,
    pushOrder: 0,
    pushMatchOpt: null,
    pushMatchOptInterval: null,
  },
};

const userState = (state = initialUserState, { type, ...rest }) => {
  switch (type) {
    case "user":
      return { ...state, ...rest };
    case "reset":
      return { ...initialUserState };
    default:
      return state;
  }
};

const persistConfig = {
  key: "root",
  blacklist: ["loadState"],
  storage,
};

const rootReducer = combineReducers({
  appState,
  loadState,
  userState,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  let store = createStore(persistedReducer);
  let persistor = persistStore(store);
  return { store, persistor };
};
