import React, { useEffect, Suspense, useCallback } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import jwt from "jsonwebtoken";
/**@lib */
import { useAxiosEsport, useAxios, GuardRoute, useSearchUser, useFlowController } from "./lib";
import { Loading, Modal, Loader } from "./components";
import "animate.css";
/**@npx node-sass .\src\App.scss .\src\App.css */
import "./App.css";
// import "./App.scss";

const Main = React.lazy(() => import("./views/main"));
const Init = React.lazy(() => import("./views/init"));
const HttpErr = React.lazy(() => import("./views/pages/httpErr"));

/**@App */
export default function App() {
  const { loading } = useSelector(state => state.appState);
  const { user } = useSelector(state => state.userState);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const httpEsportClient = useAxiosEsport();
  const httpClient = useAxios();
  const { search } = location;

  const online = useFlowController();
  const userQuery = useSearchUser();

  useEffect(() => {
    dispatch({ type: "set", loading: false });
    /**@LCK */
    const getTeams = async () => {
      let url = "/getTeams?hl=ko-KR";
      let teams = await httpEsportClient.get(url);
      teams = teams?.data?.data?.teams;
      teams = teams?.filter(r => r?.status === "active")?.filter(r => r?.homeLeague?.name === "LCK");
      dispatch({ type: "set", teams });
    };
    /**@LCKteam */
    getTeams();
    /**@Refresh */
    process?.env?.NODE_ENV != "production" && console.info("search", userQuery);
    if (userQuery) refresh(userQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && !user.ci) history.replace({ pathname: "/init", search });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const refresh = useCallback(async user =>
    await httpClient
      .put("/user/refresh", user)
      .then(r => {
        const { token } = r?.data || {};
        const user = jwt.decode(token);
        process?.env?.NODE_ENV != "production" && console.info(user);
        if (user) {
          dispatch({ type: "user", user, authenticated: true });
          history.replace({ pathname: "/home" });
        }
      }), [dispatch, history, httpClient])

  if (!online) return <Loading />;
  return (
    <>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/err" name="Err" render={props => <HttpErr {...props} />} />
          <Route path="/init" name="init" render={props => <Init {...props} />} />
          <GuardRoute user={user} path="/" name="Main" render={props => <Main {...props} />} />
        </Switch>
      </Suspense>
      <Modal show={loading} closeable={false} transparent={true}>
        <Loader />
      </Modal>
    </>
  );
}
