import React from "react";

/**@RoutesMain */
const Home = React.lazy(() => import("../views/main/home"));
const LCK = React.lazy(() => import("../views/main/LCK"));
const Bank = React.lazy(() => import("../views/main/bank"));
/**@RoutesMain in My */
const My = React.lazy(() => import("../views/main/my"));
const Reward = React.lazy(() => import("../views/main/my/reward"));
const Tier = React.lazy(() => import("../views/main/my/tier"));
const Push = React.lazy(() => import("../views/main/my/push"));
const Notice = React.lazy(() => import("../views/main/my/notice"));
const noticeDetail = React.lazy(() => import("../views/main/my/noticeDetail"));
/**@RoutesMain in Event */
const Event = React.lazy(() => import("../views/main/event"));
const GoldkingDetail = React.lazy(() => import("../views/main/event/goldking/goldkingDetail"));
const SpecialDetail = React.lazy(() => import("../views/main/event/special/specialDetail"));

/**@RoutesInit */
const Agree = React.lazy(() => import("../views/init/agree"));
const Privacy = React.lazy(() => import("../views/init/privacy"));
// const Terms = React.lazy(() => import("../views/init/terms"));
const TeamChoice = React.lazy(() => import("../views/init/team-choice"));

export const RoutesMain = [
  // { path: "/", exact: true, component: Home },
  { key: "home", path: "/home", name: "WON하는 LCK", component: Home, nav: true },
  { key: "lck", path: "/lck", name: "LCK", component: LCK, nav: true },
  /**@RoutesMain in Event */
  { key: "event", exact: true, path: "/event/:type?", name: "이벤트", component: Event, nav: true },
  { key: "goldking", path: "/event/goldking/:id", name: "선수 선택", component: GoldkingDetail },
  { key: "special", path: "/event/special/:id", name: "", component: SpecialDetail },
  //
  { key: "bank", path: "/bank", name: "금융", component: Bank, nav: true },
  /**@RoutesMain in My */
  { key: "my", exact: true, path: "/my", name: "MY", component: My, nav: true },
  { key: "team", exact: true, path: "/my/team", name: "My Team 설정", component: TeamChoice },
  { key: "reward", path: "/my/reward", name: "골드 리워드", component: Reward },
  { key: "tier", path: "/my/tier", name: "티어", component: Tier },
  { key: "push", path: "/my/push", name: "알림 설정", component: Push },
  { key: "notice", exact: true, path: "/my/notice", name: "공지사항", component: Notice },
  { key: "notice", path: "/my/notice/:id", name: "공지사항", component: noticeDetail },
];

export const RoutesInit = [
  { path: "/init", name: "", exact: true, component: Agree },
  { path: "/init/privacy", name: "", component: Privacy },
  { path: "/init/team", name: "My Team 설정", component: TeamChoice },
];
