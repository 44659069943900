export const EVENT_TYPE_SPECIAL = {
  quiz: "quiz",
  quiz_img: "quiz_img",
  quiz_ox: "quiz_ox",
  vote: "vote",
  vote_player: "vote_player",
  friend: "friend",
  coalition: "coalition",
  comment: "comment",
  attendance: "attendance",
  league: "league",
};

export const STATE_EVENT_RESULT = {
  success: "success", //예측 성공
  fail: "fail", //예측 실패
  not: "not", //미참여
  yet: "yet", //예측 진행중
  nor: "nor", //예측 마감
};

export const CATEGORY_TYPE = {
  goldking: "goldking",
  special: "special",
  predict: "predict",
  popup: "popup",
};

export const PROGRESS_TYPE = {
  inprogress: "inprogress",
  unstarted: "unstarted",
  completed: "completed",
};

export const ANSWER_TYPE = {
  correct: "correct",
  wrong: "wrong",
  notattend: "notattend",
};

export const COLOR_DEFAULT = "#463cc4";

export const TEAMS = [
  { code: "DK", color: "#64ccc9", logo: "/icon/depracated/ic-list-logo-dk.png" },
  { code: "DRX", color: "#678df7", logo: "/icon/depracated/ic-list-logo-drx.png" },
  { code: "GEN", color: "#aa8b2f", logo: "/icon/depracated/ic-list-logo-dk.png" },
  { code: "T1", color: "#ce2c35", logo: "/icon/depracated/ic-list-logo-t1.png" },
  { code: "AF", color: "#009fe3", logo: "/icon/depracated/ic-list-logo-af.png" },
  { code: "KT", color: "#ff0a07", logo: "/icon/depracated/ic-list-logo-kt.png" },
  { code: "LSB", color: "#ffc900", logo: "/icon/depracated/ic-list-logo-lsb.png" },
  { code: "NS", color: "#ec1c24", logo: "/icon/depracated/ic-list-logo-ns.png" },
  { code: "HLE", color: "#f37321", logo: "/icon/depracated/ic-list-logo-hle.png" },
  { code: "BRO", color: "#00693d", logo: "/icon/depracated/ic-list-logo-bro.png" },
  { code: "KDF", color: "#F29C8A", logo: "" },
];

export const PLAYPOSITION = {
  top: { role: "top", logo: "/icon/role/ic-roster-top.png" },
  jungle: { role: "jungle", logo: "/icon/role/ic-roster-jg.png" },
  mid: { role: "mid", logo: "/icon/role/ic-roster-mid.png" },
  bottom: { role: "bottom", logo: "/icon/role/ic-roster-ad.png" },
  support: { role: "support", logo: "/icon/role/ic-roster-sup.png" },
};

export const SECTION_TYPE = {
  financialProduct: "financialProduct",
  event: "event",
};

export const TIER_TYPE = {
  bsg: {
    key: "bsg",
    name: "브실골",
  },
  platinum: {
    key: "platinum",
    name: "플래티넘",
  },
  diamond: {
    key: "diamond",
    name: "다이아몬드",
  },
  master: {
    key: "master",
    name: "마스터",
  },
  challenger: {
    key: "challenger",
    name: "챌린저",
  },
};

export const TYPE_DEFAULT_PUSH = {
  pushMatch: 0,
  pushOrder: 0,
};

export const TYPE_DEFAULT_PUSH_OPTION = {
  allteam: "allteam",
  myteam: "myteam",
  before30m: "before30m",
  before1h: "before1h",
};

/**@LP_TYPE */
export const LP_TYPE = {
  lckSaving: {
    key: "lckSaving",
    type: "bank",
    title: "우리은행 적금 가입",
    available: 0,
    value: 20,
  },
  lckSavingAuto: {
    key: "lckSavingAuto",
    type: "bank",
    title: "우리은행 적금 자동이체 등록",
    value: 10,
  },
  houseSubscription: {
    key: "houseSubscription",
    type: "bank",
    title: "주택청약종합저축 가입",
    value: 10,
  },
  houseSubscriptionAuto: {
    key: "houseSubscriptionAuto",
    type: "bank",
    title: "주택청약종합저축 자동이체 등록",
    value: 10,
  },
  mydata: {
    key: "mydata",
    type: "bank",
    title: "마이데이터 가입 여부",
    value: 10,
  },
  openBanking: {
    key: "openBanking",
    type: "bank",
    title: "오픈뱅킹 가입 여부",
    value: 10,
  },
  WONBank: { key: "WONBank", type: "bank", title: "WON뱅킹 가입 여부", value: 10 }, //able
  WONBankAccount: {
    key: "WONBankAccount",
    type: "bank",
    title: "우리 WON 통장 보유 여부",
    value: 10,
  }, //able
  // service
  myTeam: { key: "myTeam", type: "service", title: "MY TEAM 등록", value: 5 }, //able
  pushRegist: {
    key: "pushRegist",
    type: "service",
    title: "PUSH 알림 등록",
    value: 5,
  },
  // event
  event10: {
    key: "event10",
    type: "event",
    title: "이벤트 참여 10회 이상",
    value: 10,
  },
  event20: {
    key: "event20",
    type: "event",
    title: "이벤트 참여 20회 이상",
    value: 10,
  },
  comment10: {
    key: "comment10",
    type: "event",
    title: "댓글 작성 10회 이상",
    value: 10,
  },
  //tier(lp-reward)
  bsg: {
    key: "bsg",
    type: "tier",
    title: "브실골",
    value: 0,
    point: 500,
    weight: 0,
  },
  platinum: {
    key: "platinum",
    type: "tier",
    title: "플래티넘",
    value: 0,
    point: 1000,
    weight: 1,
  },
  diamond: {
    key: "diamond",
    type: "tier",
    title: "다이아몬드",
    value: 0,
    point: 1500,
    weight: 2,
  },
  master: {
    key: "master",
    type: "tier",
    title: "마스터",
    value: 0,
    point: 2000,
    weight: 3,
  },
  challenger: {
    key: "challenger",
    type: "tier",
    title: "챌린저",
    value: 0,
    point: 2500,
    weight: 4,
  },
};

export const regxHexOnly = "^[a-fA-F0-9]+$";

// export const LCK_TOURNAMENT_ID = "107418445247362001";
// export const LCK_TOURNAMENT_ID = "108195478954601542";
// export const LCK_TOURNAMENT_ID = "109625152894842837";
export const LCK_TOURNAMENT_ID = "110371551277508787"; // 2023 LCK 서머 시즌 
