import { useCallback, useEffect, useRef } from "react";

function useMounted() {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);
  return useCallback(() => isMounted.current, []);
}

export default useMounted;
