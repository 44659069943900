import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";

const NoData = ({ title = undefined, msg = undefined, code = 404, ...props }) => {
  return (
    <div className={"noData inactive"} {...props}>
      <img src={process.env.PUBLIC_URL + "/icon/interaction/ic-error.png"} alt="err" />
      {code === 404 ? (
        <div className={"text-center"} style={{ marginTop: "42px" }}>
          <div className="noData-title" style={{ fontSize: "72px", fontFamily: "BlackHanSans" }}>
            {title ? title : "어디로 가야하오."}
          </div>
          <div className="noData-desc" style={{ marginTop: "59px" }}>
            {msg ? msg : "준비중입니다."}
          </div>
        </div>
      ) : code === 401 ? (
        <div className={"text-center text-pre"} style={{ lineHeight: "1.51" }}>
          <p className={"head-2"}>{`사용자 정보를 확인 할 수 없습니다..\n다시 접속해주세요.`}</p>
        </div>
      ) : code === 408 ? (
        <div className={"text-center text-pre"} style={{ lineHeight: "1.51" }}>
          <p className={"head-2"}>{`서비스가 일시적으로\n원활하지 않습니다.`}</p>
        </div>
      ) : (
        <div className={"text-center text-pre"} style={{ lineHeight: "1.51" }}>
          <p className={"head-2"}>{`처리 중 오류가 발생하였습니다.\n다시 시도해주세요.`}</p>
        </div>
      )}
    </div>
  );
};
export default NoData;
