import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { RoutesMain } from "../config";

const footerItems = [...RoutesMain].filter((r) => r.name && r.nav).map((j) => j);

const Footer = React.memo(() => {
  let location = useLocation();
  const { pathname } = location;
  const currentTab = pathname.split("/")[1];

  useEffect(() => {}, [location]);

  return (
    <div id="footer">
      <div className="footer-content">
        {/*  */}
        {footerItems.map((r, i) => {
          const active = r.key === currentTab ? true : false;
          return (
            <div className="footer-item" key={i}>
              <Link to={"/" + r.key}>
                <div
                  className={classnames("nav-footer-item", {
                    active: active,
                  })}
                >
                  <div
                    style={{
                      backgroundImage: active
                        ? `url(/icon/nav/ic-bottom-${r.key}-sel.png)`
                        : `url(/icon/nav/ic-bottom-${r.key}-nor.png)`,
                    }}
                    className={`footer-ic ic-footer-${r.key}`}
                  />
                  <div>{r.name === "WON하는 LCK" ? "홈" : r.name}</div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
});
export default Footer;
