import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import classnames from 'classnames';
import { COLOR_DEFAULT } from '../config/const';

const duration = 0.3;

const StyledNav = styled.nav`
  display: flex;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding: 0 20px;
`;

const StyledNavItem = styled.div`
  text-align: center;
  cursor: pointer;
  color: #000;
  padding: 20px;
  transition: ${duration}s;
  text-decoration: none;
  margin: 0 6px;
  z-index: 1;
  position: relative;
  &.active {
    :before {
      font-weight: bold;
    }
  }
  &:before {
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 100%;
    height: 8px;
    opacity: 0;
    transition: ${duration}s;
  }
  &:not(.active):hover:before {
    opacity: 1;
    bottom: 0;
  }
  &:not(.active):hover {
    color: #fff;
  }
`;

const StyledNavIndicator = styled.span`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 8px;
  z-index: 1;
  transition: ${duration}s;
`;

const Navigator = ({ tabs = [], onChangeTab, themeColor = COLOR_DEFAULT, ...props }) => {
  const anchorEl = useRef([]);
  const indicator = useRef(null);

  // const themeColor = COLOR_DEFAULT;
  // import { TEAMS, COLOR_DEFAULT } from "../../config/const";
  // let themeColor = COLOR_DEFAULT;
  // themeColor = TEAMS.find((r) => r?.code === team?.code).color;

  useEffect(() => {
    anchorEl.current.forEach((item) => {
      if (item.classList.contains('active')) {
        item.style.color = themeColor;
        locateIndicator(item);
      } else {
        item.style.color = 'rgba(255, 255, 255, 0.3)';
      }
    });
  }, [tabs]);

  const onClickItem = (e) => {
    const update = [...tabs].map((r) => {
      r.isActive = r.name === e.target.textContent ? true : false;
      return r;
    });
    onChangeTab({ type: 'set', tabs: update });
  };

  const locateIndicator = (el) => {
    indicator.current.style.width = `${el.offsetWidth}px`;
    indicator.current.style.left = `${el.offsetLeft}px`;
    indicator.current.style.backgroundColor = el.getAttribute('data-color');
  };

  return (
    <StyledNav
      id={'nav'}
      {...props}>
      {tabs.map((r, i) => {
        return (
          <StyledNavItem
            key={i}
            ref={(ref) => (anchorEl.current[i] = ref)}
            onClick={onClickItem}
            className={classnames('nav-item', { active: r.isActive })}
            data-color={themeColor || 'transparent'}>
            {r.name}
          </StyledNavItem>
        );
      })}
      <StyledNavIndicator
        ref={indicator}
        className='nav-indicator'></StyledNavIndicator>
    </StyledNav>
  );
};
export default Navigator;
