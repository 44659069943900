import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import ICchecked from "./ic-checked.png";
import ICunchecked from "./ic-unchecked.png";
import "./switch.css";

const warnningColor = "red";

//must outside InputText
const InputText = styled.input`
  border-radius: 5px;
  border: solid 2px #000;
  height: 61px;
  width: 100%;
  color: black;
  padding: 24px 20px;
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
  &.error {
    border: solid 2px ${warnningColor};
  }
  &:focus:required:invalid {
    border: solid 2px ${warnningColor};
  }
  &:required:valid {
    border: solid 2px green !important;
  }
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  /* display: none; //focusable issue*/
  opacity: 0;
  width: 1px;
`;

const StyledCheckbox = styled.label`
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(${ICunchecked});
  ${HiddenCheckbox}:checked + & {
    background-image: url(${ICchecked});
  }
`;
/**
 * @name Input
 */
const Input = ({ ...props }) => {
  const inputEl = useRef();
  useEffect(() => {
    const { current } = inputEl;
    const handleError = () => {
      current.setCustomValidity(getMessageWithLabel());
    };
    if (current) {
      current.addEventListener("invalid", handleError);
    }
    return () => {
      if (current) {
        current.removeEventListener("invalid", handleError);
      }
    };
  }, []);

  const validityMessage = {
    badInput: "잘못된 입력입니다.",
    patternMismatch: "패턴에 맞게 입력하세요.",
    rangeOverflow: "범위를 초과하였습니다.",
    rangeUnderflow: "범위에 미달하였습니다.",
    stepMismatch: "간격에 맞게 입력하세요.",
    tooLong: "최대 글자 미만으로 입력하세요.",
    tooShort: "최소 글자 미만으로 입력하세요.",
    typeMismatch: "형식에 맞지 않습니다.",
    valueMissing: "작성이 필요합니다.",
  };

  const getMessageWithLabel = () => {
    const { validity, attributes } = inputEl.current;
    let errMessage = getValidityMessage(validity) || "";
    if (errMessage) {
      const label = attributes.label ? attributes.label.value + "\t" : "";
      errMessage = label + errMessage;
    }
    return errMessage;
  };

  function getValidityMessage(validity) {
    for (const key in validityMessage) {
      if (validity[key]) {
        return validityMessage[key];
      }
    }
  }

  return (
    <>
      {props.type === "checkbox" ? (
        <div className={props.className} style={{ position: "relative" }}>
          <HiddenCheckbox
            ref={inputEl}
            type={props.type}
            id={props.name}
            name={props.name}
            {...props}
          />
          <StyledCheckbox htmlFor={props.name} />
          <span
            style={{
              marginLeft: "10px",
              verticalAlign: "middle",
              color: " #303030",
              width: "auto",
              whiteSpace: "nowrap",
            }}
          >
            {props.label}
          </span>
          {props.error ? <ErrorInput msg={getMessageWithLabel()} /> : undefined}
        </div>
      ) : props.type === "switch" ? (
        <label className={"switch " + (props.className || "")}>
          <input
            /***/
            {...props}
            ref={inputEl}
            type="checkbox"
          />
          <span className="slider round" />
        </label>
      ) : (
        <div className={props.className} style={{ paddingBottom: "1em" }}>
          <h6 style={{ marginBottom: "8px" }}>{props.label}</h6>
          <InputText
            ref={inputEl}
            style={props.error ? { borderColor: warnningColor } : undefined}
            type={props.type ? props.type : "text"}
            placeholder={props.placeholder ? props.placeholder : ""}
            name={props.name}
            {...props}
          />
          {props.error ? <ErrorInput msg={getMessageWithLabel()} /> : undefined}
        </div>
      )}
    </>
  );
};

const ErrorInput = ({ msg }) => {
  return (
    <div style={{ position: "absolute" }}>
      <p style={{ fontSize: "1em", color: warnningColor }}>{msg ? msg : "error"}</p>
    </div>
  );
};

export default Input;
export { ErrorInput };
