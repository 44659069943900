import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
/**@useFlowController */
export default function useFlowController() {
  const netFunnel = window.NetFunnel;
  const ACT15005 = window.ACT15005;
  const dispatch = useDispatch();
  const { online } = useSelector(state => state.appState);
  const { NetFunnel_Action, NetFunnel_Complete } = netFunnel || {};

  useEffect(() => {
    let timer = null;
    window.addEventListener("beforeunload", handleUnload);
    if (!netFunnel) return dispatch({ type: "set", online: true });
    if (typeof NetFunnel_Action == "function") {
      dispatch({ type: "set", online: false });
      try {
        NetFunnel_Action(
          { service_id: "service_2", action_id: "lck_product" },
          {
            success: (ev, ret) => {
              dispatch({ type: "set", online: true });
              timer = setTimeout(() => {
                handleUnload();
              }, 40000);
            },
            stop: function (ev, ret) {
              handleUnload();
              ACT15005();
            },
            error: function (ev, ret) {
              handleUnload();
              ACT15005();
            },
          },
        );
      } catch (err) {
        handleUnload();
        process?.env?.NODE_ENV != "production" && console.error(err);
      }
    }
    return () => {
      clearTimeout(timer);
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  const handleUnload = () => {
    dispatch({ type: "set", online: true });
    NetFunnel_Complete();
  };

  return online;
}
