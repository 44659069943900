import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PageLists = styled.ul`
  display: flex;
`;
const ButtonCommon = styled.button`
  color: black;
  width: 26px;
  height: 26px;
  padding: 0;
  // padding: 0.5rem 0.75rem;
  border: solid 1px #caccd5;
  cursor: pointer;
  background: none;
  border-radius: 0;
  margin: 0 0.3rem;
  line-height: 0;
`;
const PageNumber = styled.li``;
const PageButton = styled(ButtonCommon)`
  font-size: 15px;
  color: ${(props) => props.color};
  &.active {
    color: #ffffff;
    background-color: #1c2349;
  }
`;
const ArrowButton = styled(ButtonCommon)`
  width: 36px;
  background-color: #1c2349;
  color: #fff;
`;

function Pagination({ limits = 10, total, paginate, current = 1, offset = 10 }) {
  const [pageNumbers, setPageNumbers] = useState([]);

  useEffect(() => {
    const idx = currentPage();
    setPageNumbers(listPageNumbers(idx));
  }, [total]);

  useEffect(() => {}, [current]);

  const currentPage = () => {
    const index = current / offset;
    const currentOffeset = Math.ceil(index);
    return currentOffeset;
  };

  const listPageNumbers = (pageOffset) => {
    const totalCountOfPage = Math.ceil(total / limits);
    let max = pageOffset * offset;
    let list = [];
    if (max < 1) return list;
    if (max > totalCountOfPage) max = totalCountOfPage;
    do {
      list.push(max);
      max--;
    } while (max % offset);
    list.reverse();
    return list;
  };

  const prevPageOffset = () => {
    const prev = currentPage() - 1;
    if (prev <= 0) return;
    const list = listPageNumbers(prev);
    setPageNumbers(list);
    const position = list[list.length - 1];
    paginate(position);
  };

  const nextPage = () => {
    const next = currentPage() + 1;
    const totalCountOfPage = Math.ceil(total / limits);
    const maxOffset = Math.ceil(totalCountOfPage / offset);
    if (next > maxOffset) return;
    const list = listPageNumbers(next);
    setPageNumbers(list);
    const position = list[0];
    paginate(position);
  };

  return (
    <Wrapper>
      <ArrowButton onClick={prevPageOffset}>&lt;</ArrowButton>
      <PageLists>
        {pageNumbers.map((number) => {
          return (
            <PageNumber key={number}>
              <PageButton
                className={number == current ? "active" : ""}
                onClick={() => paginate(number)}
              >
                {number}
              </PageButton>
            </PageNumber>
          );
        })}
      </PageLists>
      <ArrowButton onClick={nextPage}>&gt;</ArrowButton>
    </Wrapper>
  );
}
export default Pagination;

// for (let i = 1; i <= totalCountOfPage; i++) {
//   pageNumbers.push(i);
// }
/*
{pageNumbers.map((number) => (
  <PageNumber key={number}>
    <PageButton onClick={() => paginate(number)}>{number}</PageButton>
  </PageNumber>
))}
*/
