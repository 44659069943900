import React from "react";
import styled from "styled-components";
import ReactLogo from "./ic-input-search-notice.svg";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 53px;
`;
const Input = styled.input`
  width: 100%;
  max-width: 460px;
  height: 57px;
  margin-right: 20px;
  border: solid 1px #caccd5;
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: 10px center;
  &:focus {
    background-image: none !important;
    padding: 17px !important;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: transparent;
    }
  }
`;
const Button = styled.button`
  width: 140px;
  height: 57px;
  background-color: #1c2349;
`;

const Search = ({ text, onChange, onClick }) => {
  return (
    <Wrapper>
      <Input
        placeholder="검색어를 입력해주세요"
        style={{
          backgroundImage: text ? undefined : `url(${ReactLogo})`,
          padding: text ? "17px" : " 17px 57px",
        }}
        value={text}
        onChange={onChange}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            onClick(e);
          }
        }}
      />
      <Button onClick={onClick}>검색</Button>
    </Wrapper>
  );
};
export default Search;
