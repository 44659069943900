import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const menuPosition = "fixed";
const verticalValue = "16px";
const horizontalValue = "50px";
const zIndex = 10; // Sidebar > header(9)

const Overlay = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
  will-change: opacity;
`;
const StyledSidebar = styled.div`
  position: ${menuPosition};
  top: ${verticalValue};
  left: ${horizontalValue};
  z-index: ${zIndex};
  display: block;
  user-select: none;
  cursor: ${(props) => (props.btnOnly ? "pointer" : "auto")};
  input {
    position: ${menuPosition};
    top: ${verticalValue};
    left: ${horizontalValue};
    z-index: ${(props) => (props.btnOnly ? zIndex - 1 : zIndex + 1)};
    cursor: pointer;
    display: block;
    width: 33px;
    height: 27px;
    opacity: 0;
    -webkit-touch-callout: none;
  }
  input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg);
    background: #000;
  }
  input:checked ~ span:nth-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }
  input:checked ~ span:nth-child(4) {
    transform: rotate(-45deg);
  }
  input:checked ~ #side-panel {
    transform: none;
  }
  input:checked ~ ${Overlay} {
    display: block;
  }
  &.right {
    right: ${horizontalValue};
    left: auto;
    input {
      right: ${horizontalValue};
      left: auto;
    }
    #side-panel {
      left: auto;
      right: 0;
      transform: translate(100%, 0);
    }
  }
`;
const StyledSpan = styled.span`
  display: block;
  width: 33px;
  height: 4px;
  position: relative;
  background: #000;
  border-radius: 3px;
  z-index: 1;
  transition: transform 0.5s;
`;
const Top = styled(StyledSpan)`
  transform-origin: 0% 0%;
`;
const Middle = styled(StyledSpan)`
  margin-top: 7px;
  margin-bottom: 7px;
  transform-origin: 0% 0%;
`;
const Bottom = styled(StyledSpan)`
  transform-origin: 0% 100%;
`;
const Panel = styled.div`
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  transform: translate(-100%, 0);
  width: 300px;
  height: 100vh;
  padding: 50px;
  padding-top: 125px;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  transform-origin: 0% 0%;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
`;

const Sidebar = ({ children, position = "", btnOnly = false, callback, overlay = false }) => {
  let checkbox;
  let location = useLocation();

  useEffect(() => {
    setCheckFalse();
  }, [location]);

  const changedCheck = (e) => {
    if (!overlay) return;
    if (e.target.checked) {
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.overflow = "hidden";
      document.body.style["padding-right"] = `${scrollbarWidth}px`;
    } else {
      document.body.style["overflow"] = null;
      document.body.style["padding-right"] = null;
    }
  };

  const setCheckFalse = (e) => {
    checkbox.checked = false;
  };

  return (
    <StyledSidebar
      id={"sidebar"}
      className={position}
      onClick={btnOnly ? callback : undefined}
      btnOnly={btnOnly}
    >
      {btnOnly ? undefined : (
        <input
          type={"checkbox"}
          ref={(element) => {
            checkbox = element;
          }}
          onChange={(e) => changedCheck(e)}
        />
      )}
      <Top />
      <Middle />
      <Bottom />
      <Panel id={"side-panel"}>{children}</Panel>
      {overlay ? <Overlay onClick={setCheckFalse} /> : undefined}
    </StyledSidebar>
  );
};

export default Sidebar;
