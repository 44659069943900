import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import classnames from "classnames";
/**@lib */
import "./accordion.css";
import svgUp from "./ic-arrow-up-dark.svg";
import svgDown from "./ic-arrow-dark.svg";

let init = true;
let ACT15006 = window.ACT15006;

const AccordionContainer = styled.div`
  &.light {
    background-color: ${({ theme }) => theme.bg};
    .accordion-trigger {
      background-color: ${({ theme }) => theme.bgDark};
      background-image: url(${({ theme }) => theme.svgUp});
      .accordion-anchor {
        color: ${({ theme }) => theme.black};
      }
    }
    &.active {
      .accordion-trigger {
        background-image: url(${({ theme }) => theme.svgDown});
      }
    }
  }
`;

export default React.memo(({ className = "", anchorEl, children, animate = false, theme = "", eventId }) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(true);

  let hardUrl = "https://drive.google.com/drive/folders/1iNYpVKNjCtbqYnabrP8DHtCjD3Xk23z_?usp=drive_link";
  const themeLight = {
    bg: "#dedee5",
    bgDark: "#cfd0d6",
    black: "rgba(25, 23, 41, 0.8)",
    svgUp,
    svgDown,
  };

  useEffect(() => {
    return () => (init = true);
  }, []);

  const toggle = () => {
    const is = !isActive;
    setIsActive(is);
    if (is) {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 100);
    }else{
      if(eventId == '829'){ // LoL 국가대표팀 사진 배포 이벤트
        ACT15006(hardUrl);
      }
    }
  };

  return (
    <AccordionContainer
      className={classnames("accordion-container", className, {
        active: isActive,
        light: theme == "light",
      })}
      onClick={toggle}
      theme={theme == "light" ? themeLight : undefined}
    >
      <div className="accordion-trigger">{anchorEl}</div>
      <div ref={dropdownRef} className="accordion">
        {children}
      </div>
    </AccordionContainer>
  );
});

const IconUp = ({ stroke = "#fff" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M0 0h44V34H0z"
                transform="translate(-428 -364) translate(250 100) translate(0 252) matrix(1 0 0 -1 178 46)"
              />
              <path
                stroke={stroke}
                stroke-width="1.6"
                d="M12 14L17.315 19.368 22.631 14"
                transform="translate(-428 -364) translate(250 100) translate(0 252) matrix(1 0 0 -1 178 46)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
