import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import classnames from "classnames";

const Loading = ({ full = true, bg = true, reloading = true, className, ...props }) => {
  const StyledMask = styled.div``;
  const [reload, setReload] = useState(new Date().getTime());

  return (
    <div
      className={classnames(`layout-flex-center ${className ? className : ""}`, {
        "h-100vh": full,
        "bg-gradient": bg,
      })}
      {...props}
    >
      <div className="layout-flex-center-inner">
        <StyledMask
          className="loader"
          style={{ backgroundImage: `url(/icon/progress.gif?${reloading ? reload : ""})` }}
        />
      </div>
    </div>
  );
};
export default Loading;
