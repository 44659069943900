import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
/**@lib */
import { compareDate, isDoneToday } from "../lib/util";
import Input from "./input";
import { PROGRESS_TYPE } from "../config/const";
/**@modal */

const paddingSideMoal = 45;
const StyledModalWindow = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${paddingSideMoal}px;
  /* opacity: 0; */
  /* visibility: hidden; */
  /* pointer-events: none; */
  z-index: 999;
`;

const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 990px;
  max-height: 90%;
  overflow: hidden;
  width: ${({ full }) => (full ? "100%" : "auto")};
  ${({ full }) =>
    full &&
    css`
      min-width: 990px;
    `};
`;

const StyledModalBody = styled.div`
  width: auto;
  background-color: #fff;
  overflow: auto;
  ${({ transparent }) =>
    transparent
      ? css`
          background-color: transparent;
        `
      : css`
          background-color: #fff;
        `};
`;

const StyledModalConfirm = styled.div`
  cursor: pointer;
  width: 100%;
  height: 166px;
  max-height: 166px;
  .btn-ok {
    background-color: #463cc4;
  }
  .btn-no {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.5);
  }
  > button {
    width: 100%;
    height: 100%;
    color: #fff;
  }
  &.alert {
    display: block;
  }
  &.confirm {
    display: flex;
  }
`;

const StyledBannerBtn = styled.div`
  background-color: #463cc4;
  display: flex;
  align-items: stretch;
  & > :nth-child(1) {
    display: flex;
    align-items: center;
    padding-left: 30px;
    flex: 1;
  }
  & > :nth-child(2) {
    width: 300px;
  }
  & div {
    white-space: nowrap;
  }
`;

const MODAL_TYPE = {
  alert: "alert",
  confirm: "confirm",
  banner: "banner",
};

export default function Modal({
  children,
  show = false,
  type = "",
  onClose = null,
  full = false,
  popup = null,
  closeable = true,
  transparent = false,
}) {
  const [isShow, setIsShow] = useState(false);
  const refEl = useRef(null);

  useEffect(() => {
    setIsShow(show);
  }, [show]);

  useEffect(() => {
    changedCheck(isShow);
  }, [isShow]);

  useEffect(() => {
    if (popup) {
      const { startDate, endDate } = popup || {};
      const progress = compareDate(startDate, endDate);
      if (progress == PROGRESS_TYPE.inprogress && !isDoneToday()) setIsShow(true);
      else setIsShow(false);
    }
  }, [popup]);

  const handleBlur = (e) => {
    if (!closeable) return;
    if (type == MODAL_TYPE.confirm || type == MODAL_TYPE.banner) return;
    if (!refEl.current || !refEl.current.contains) return;
    if (!refEl.current.contains(e.target)) {
      onModalClose(false);
    }
  };

  const onModalClose = (bool = false) => {
    if (onClose) onClose(bool);
    setIsShow(false);
    /**@type banner */
    if (bool && type == MODAL_TYPE.banner) {
      isDoneToday({ ok: true });
    }
  };

  const changedCheck = (isShow) => {
    if (isShow) {
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.overflow = "hidden";
      document.body.style["padding-right"] = `${scrollbarWidth}px`;
    } else {
      document.body.style["overflow"] = null;
      document.body.style["padding-right"] = null;
    }
  };

  return (
    <>
      {isShow ? (
        <StyledModalWindow onClick={handleBlur} className={"modal-container"}>
          <StyledModal style={{ display: "flex", flexDirection: "column" }} full={full}>
            <StyledModalBody className="modal-content" ref={refEl} transparent={transparent}>
              {children}
            </StyledModalBody>
            <StyledModalConfirm className={"modal-confirm " + type}>
              {type === MODAL_TYPE.confirm ? (
                <>
                  <button className={"btn-no"} onClick={() => onModalClose(false)}>
                    취소
                  </button>
                  <button
                    className={"btn-ok"}
                    onClick={() => {
                      onModalClose(true);
                    }}
                  >
                    확인
                  </button>
                </>
              ) : undefined}
              {type === MODAL_TYPE.alert ? (
                <button className={"btn-ok"} onClick={() => onModalClose(false)}>
                  확인
                </button>
              ) : undefined}
              {type === MODAL_TYPE.banner ? (
                <StyledBannerBtn>
                  <div
                    onClick={() => {
                      onModalClose(true);
                    }}
                  >
                    <Input type={"checkbox"} label={"오늘 안 보기"} className={"input-checkbox"} />
                  </div>
                  <button className={"btn-no"} onClick={() => onModalClose(false)}>
                    닫기
                  </button>
                </StyledBannerBtn>
              ) : undefined}
            </StyledModalConfirm>
          </StyledModal>
        </StyledModalWindow>
      ) : undefined}
    </>
  );
}
