import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useHistory, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import classnames from "classnames";
/** @lib */
import { TeamLogo } from "../index";

const StyledHeader = styled.div`
  position: fixed;
  z-index: 11;
  width: 100%;
  display: flex;
  top: 0;
  left: 0;
  .header-content {
    display: flex;
    align-items: center;
    width: 100%;
    .header-back {
      text-decoration: none;
      cursor: pointer;
      width: 76px;
      text-indent: -9999px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .header-menu {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex: 1;
      height: 100%;
    }
    .spacing {
      width: 90px;
      height: 100%;
    }
  }
  &.flying {
    background-color: transparent;
  }
`;

const ACT15002 = window.ACT15002;
const ACT15005 = window.ACT15005;

const Header = ({ children, munu = true, routes = null, team }) => {
  const { pageName } = useSelector(state => state.loadState);
  const { user } = useSelector(state => state.userState);
  const [flying, setFlying] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const { path } = match;
  const { pathname, search } = location;
  const [routeName, setRouteName] = useState("");
  const [disabled] = useState(user?.sp == "Y");

  useEffect(() => {
    historyObserve();
    window.addEventListener("popstate", handlePop);
    return () => window.removeEventListener("popstate", handlePop);
  }, [historyObserve]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [location]);

  useEffect(() => {
    breadcrumb();
  }, [breadcrumb, match]);

  const handleScroll = e => {
    const top = document.querySelector("body").scrollTop || document.querySelector("html").scrollTop;
    setFlying(top > 0 ? true : false);
  };

  const handlePop = e => process?.env?.NODE_ENV != "production" && console.info(e);

  const historyObserve = useCallback(e => {
    history.listen((l, a) => {
      dispatch({ type: "set", pageName: "" });
    });
  }, [dispatch, history]);

  const breadcrumb = useCallback(() => {
    if (routes) {
      const route = routes.find(r => r.path === path);
      let routeName = route?.name;
      if (routeName === "홈") routeName = "";
      setRouteName(routeName);
    }
  }, [path, routes]);

  const onBack = () => {
    let routing = pathname.split("/");
    routing.pop();
    routing = routing.join("/");
    if (path === "/init/team") return;
    if (path === "/init/privacy") return history.goBack();
    if ((path === "/" || path === "/home" || path === "/init") && !routing) {
      const exitMsg = "WON하는 LCK를 종료하고\n[우리WON뱅킹]메인으로\n이동하시겠습니까?";
      if (window.confirm(exitMsg)) ACT15005();
      return;
    }
    history.replace(routing ? routing + search : "/");
  };

  const onMenu = () => {
    if (disabled) {
      ACT15005();// ACT15000("NPPID0136&isFromLCK=Y", window.location.href);
      return;
    }
    ACT15002();
  }

  return (
    <>
      <StyledHeader id="header" className={`${flying ? "flying" : ""}`}>
        <div className="header-content">
          <h1 className="header-back" onClick={onBack}>
            logo
          </h1>
          <div className={classnames("header-menu", { chaney: path == "/lck" })}>
            {path == "/home" ? (
              <img alt="app-bar" src={process.env.PUBLIC_URL + "/img/app_bar_title.png"} />
            ) : (
              routeName || pageName
            )}
          </div>
          <div className="spacing">
            {munu ? (
              path == "/lck" && team ? (
                <TeamLogo code={user.team} />
              ) : path == "/home" ? (
                <div className={"btn-menu"} onClick={onMenu} />
              ) : undefined
            ) : undefined}
          </div>
        </div>
      </StyledHeader>
    </>
  );
};
export default Header;
